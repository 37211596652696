/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import "./Layout.scss";

import Header from "./Header"
import Footer from "./Footer";
import SEO from "./SEO";
import StreamingServices from "../StreamingServices";
import {graphql, useStaticQuery} from "gatsby";

const Layout = ({ children }) => {

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        url
                    }
                }
            }
        `
    );

    const iconURL =  site.siteMetadata.url + '/icons';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, width=device-width, height=device-height" />

                <link rel="apple-touch-icon" sizes="180x180" href={iconURL + "/apple-touch-icon.png"} />
                <link rel="icon" type="image/png" sizes="32x32" href={iconURL + "/favicon-32x32.png"} />
                <link rel="icon" type="image/png" sizes="16x16" href={iconURL + "/favicon-16x16.png"} />
                <link rel="manifest" href={iconURL + "/site.webmanifest"} />
                <link rel="mask-icon" href={iconURL + "/safari-pinned-tab.svg"} color="#63db52" />
                <link rel="shortcut icon" href={iconURL + "/favicon.ico"} />
                <meta name="msapplication-TileColor" content="#575756" />
                <meta name="msapplication-config" content={iconURL + "/browserconfig.xml"} />
                <meta name="theme-color" content="#575756" />
            </Helmet>

            <SEO />

            <Header />
            {children}
            <StreamingServices/>
            <Footer />
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
