import React from "react"
import Logo from "../../images/svg/Logo";
import "./Header.scss";

const Header = () => (
  <div className="Header">
      <div className="container">
          <div className="Header__layout">
              <div className="Header__text">
                  <h1 className="Header__title">Mittelrhein Podcast</h1>
                  <h2 className="Header__subtitle">Menschen im FreyGespräch mit Andreas Frey</h2>
              </div>
              <Logo className={"Header__logo"} />
          </div>
      </div>
  </div>
);

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
