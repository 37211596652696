import React from "react";
import "./Intro.scss";
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
  query {
    file(relativePath: { eq: "afrey.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 255) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Intro = () => {
    const data = useStaticQuery(query);
    return (
        <div className="Intro">
            <div className="container">
                <div className="row Intro__row">
                    <div className="col-xs-12 col-sm-4 col-lg-3">
                        <Img fluid={data.file.childImageSharp.fluid} className="Intro__photo" alt="Andreas Frey"/>
                    </div>
                    <div className="col-xs-12 col-sm-8 col-lg-8">
                        <p>
                            „Der Podcast mit Menschen und Unternehmen, rund um bewegende Geschichten aus Deiner Region – dem Mittelrhein.
                            Interessante und informative Themen treffen auf bewegende und authentische Storys, immer im Hinblick auf Veränderung als Kern der Diskussion.“
                        </p>
                        <p className="Intro__source">– Andreas Frey
                            <span className="Intro__website">Mehr Infos auf <a href="https://fitundfrey.de" target="_blank" rel="noopener noreferrer"  title="fitundfrey.de" className="Intro__link">fitundfrey.de</a></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Intro;