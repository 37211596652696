import React from 'react'
import Logo from "../../images/svg/Logo";
import './Footer.scss';
import {Facebook, Instagram} from "../../images/svg/Icons";

const Footer = () => (
    <div className="Footer">
        <div className="container">
            <div className="Footer__layout">
                <Logo className="Footer__logo" />
                <div className="Footer__content">
                    <div className="Footer__social">
                        <span>@mittelrheinpodcast auf</span>
                        <a href="https://www.facebook.com/mittelrheinpodcast/" title="Mittelrhein Podcast auf Facebook" className="Footer__social-link Footer__social-link--facebook">
                            <Facebook className="Footer__social-icon" />
                        </a>
                        <span>und</span>
                        <a href="https://www.instagram.com/mittelrheinpodcast/" title="Mittelrhein Podcast auf Instagram" className="Footer__social-link Footer__social-link--instagram">
                            <Instagram className="Footer__social-icon" />
                        </a>
                    </div>
                    <div className="Footer__legal">
                        <span>
                            Der Mittelrhein Podcast ist ein Angebot von <a href="https://fitundfrey.de" rel="noopener noreferrer"  title="fit &amp; frey" target="_blank">fitundfrey.de</a>
                        </span>
                        <a href="https://fitundfrey.de/datenschutz/" title="Datenschutz" rel="noopener noreferrer"  target="_blank">Datenschutz</a>
                        <a href="https://fitundfrey.de/impressum/" title="Impressum" rel="noopener noreferrer"  target="_blank">Impressum</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;