import React from "react";

export const Left = props => (
    <svg width="25px" height="85px" viewBox="0 0 25 85" {...props}>
        <path
            d="M23.232 157c.325 0 .65-.264.976-.793.325-.529.57-1.037.732-1.525l-1.098-.854c-1.383-2.277-4.86-9.11-10.431-20.496-5.571-11.387-8.357-17.405-8.357-18.056 0-2.115.671-5.022 2.013-8.723 1.342-3.7 2.989-7.503 4.941-11.407a162.18 162.18 0 005.429-12.017c1.586-3.945 2.542-7.137 2.867-9.577l-1.586-1.22c-.57.081-1.098.61-1.586 1.586-.895 1.545-1.667 3.7-2.318 6.466-.488 1.301-.732 2.074-.732 2.318-.57 1.79-1.952 5.083-4.148 9.882L3.59 107.102c-1.87 4.636-2.806 7.401-2.806 8.296 0 1.22.732 2.806 2.196 4.758 1.545 2.033 2.44 3.416 2.684 4.148.244.732.732 1.708 1.464 2.928l1.22 2.684 6.1 12.688 2.074 4.636c1.952 4.392 3.579 7.564 4.88 9.516h.244l.854.122c.163.081.407.122.732.122z"
            transform="translate(-169 -1751) translate(169 1679)"
            fill="#575756"
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1}
        />
    </svg>
);

export const Right = props => (
    <svg width="25px" height="85px" viewBox="0 0 25 85" {...props}>
        <path
            d="M1072.004 157l.854-.122.976-.122 2.562-5.124 10.492-21.716c.081-.407.488-1.26 1.22-2.562l1.464-3.05c.244-.732 1.139-2.115 2.684-4.148 1.464-1.952 2.196-3.538 2.196-4.758 0-1.057-.976-3.823-2.928-8.296l-6.222-14.518c-2.196-4.799-3.579-8.093-4.148-9.882l-.732-2.44c-.57-1.952-.895-3.09-.976-3.416a13.18 13.18 0 00-1.342-2.989c-.57-.935-1.098-1.444-1.586-1.525l-1.586 1.22c.325 2.44 1.281 5.632 2.867 9.577a162.181 162.181 0 005.429 12.017c1.952 3.904 3.599 7.706 4.941 11.407 1.342 3.7 2.013 6.608 2.013 8.723 0 .57-2.704 6.364-8.113 17.385-5.409 11.02-8.967 18.076-10.675 21.167l-1.098.854c.081.488.305.996.671 1.525.366.529.712.793 1.037.793z"
            transform="translate(-1239 -1751) translate(169 1679)"
            fill="#575756"
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1}
        />
    </svg>
);