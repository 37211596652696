import React from "react"
import PropTypes from "prop-types"

export const Facebook = props => (
    <svg width="57px" height="57px" viewBox="0 0 57 57" {...props}>
        <path
            d="M57 28.5C57 12.762 44.238 0 28.5 0S0 12.762 0 28.5 12.762 57 28.5 57c.167 0 .334-.003.501-.007V34.807h-6.123v-7.135H29V22.42c0-6.09 3.718-9.404 9.15-9.404 2.602 0 4.838.194 5.49.28v6.365h-3.746c-2.956 0-3.528 1.405-3.528 3.466v4.545h7.067l-.921 7.135h-6.146V55.9C48.279 52.484 57 41.509 57 28.5z"
            transform="translate(-1086 -2286) translate(773 2286) translate(313)"
            fill={props.fill}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1}
        />
    </svg>
);
Facebook.defaultProps = {
    fill: '#63DB52'
};
Facebook.propTypes = {
    fill: PropTypes.string.isRequired
};

export const Instagram = props => (
    <svg width="57px" height="57px" viewBox="0 0 57 57" {...props}>
        <g fill={props.fill} fillRule="nonzero" stroke="none" strokeWidth={1}>
            <path
                d="M33.911 28.5a5.411 5.411 0 11-10.822 0 5.411 5.411 0 0110.822 0z"
                transform="translate(-1213 -2286) translate(773 2286) translate(440)"
            />
            <path
                d="M41.193 18.897a5.045 5.045 0 00-1.218-1.872 5.041 5.041 0 00-1.872-1.218c-.574-.222-1.436-.488-3.023-.56-1.718-.078-2.232-.095-6.58-.095s-4.862.016-6.58.095c-1.587.072-2.449.338-3.022.56a5.038 5.038 0 00-1.872 1.218 5.048 5.048 0 00-1.219 1.872c-.222.574-.488 1.436-.56 3.024-.078 1.717-.095 2.231-.095 6.58 0 4.347.017 4.861.095 6.579.072 1.587.338 2.449.56 3.023a5.047 5.047 0 001.218 1.872c.526.54 1.165.957 1.872 1.218.574.223 1.436.488 3.024.56 1.717.079 2.231.095 6.579.095s4.863-.016 6.58-.095c1.587-.072 2.449-.337 3.023-.56a5.392 5.392 0 003.09-3.09c.222-.574.488-1.436.56-3.023.079-1.718.095-2.232.095-6.58s-.016-4.862-.095-6.58c-.072-1.587-.337-2.449-.56-3.023zM28.5 36.86a8.36 8.36 0 110-16.72 8.36 8.36 0 110 16.72zm8.691-15.098a1.954 1.954 0 110-3.907 1.954 1.954 0 010 3.907z"
                transform="translate(-1213 -2286) translate(773 2286) translate(440)"
            />
            <path
                d="M28.5 0C12.762 0 0 12.762 0 28.5S12.762 57 28.5 57 57 44.238 57 28.5 44.238 0 28.5 0zm16.267 35.247c-.08 1.742-.357 2.932-.761 3.973a8.368 8.368 0 01-4.786 4.786c-1.041.404-2.23.68-3.972.76-1.746.08-2.303.1-6.748.1-4.444 0-5.002-.02-6.747-.1-1.742-.08-2.932-.356-3.972-.76a8.018 8.018 0 01-2.9-1.888 8.02 8.02 0 01-1.886-2.898c-.405-1.041-.682-2.23-.761-3.972-.08-1.746-.1-2.304-.1-6.748 0-4.444.02-5.002.1-6.747.079-1.742.355-2.932.76-3.973a8.017 8.017 0 011.888-2.898 8.016 8.016 0 012.898-1.888c1.041-.404 2.23-.68 3.973-.76 1.745-.08 2.303-.1 6.747-.1s5.002.02 6.747.1c1.742.08 2.932.356 3.973.76a8.022 8.022 0 012.899 1.888 8.019 8.019 0 011.887 2.898c.405 1.041.681 2.23.761 3.973.08 1.745.098 2.303.098 6.747s-.018 5.002-.098 6.747z"
                transform="translate(-1213 -2286) translate(773 2286) translate(440)"
            />
        </g>
    </svg>
);
Instagram.defaultProps = {
    fill: '#63DB52'
};
Instagram.propTypes = {
    fill: PropTypes.string.isRequired
};