import React from "react"
import Layout from "../components/basic/Layout"
import Intro from "../components/Intro";
import Episodes from "../components/Episodes";
import StreamingServices from "../components/StreamingServices";

const IndexPage = () => (
  <Layout>
      <Intro />
      <StreamingServices />
      <Episodes/>
  </Layout>
);

export default IndexPage
