import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types"
import './SingleEpisode.scss';

const SingleEpisode = (props) => {

    const {episode, visible} = props;

    const scriptContainer = useRef(null);

    useEffect(() => {
        scriptContainer.current.innerHTML = "";
        if(episode.embed_config) {
            let scriptTag = document.createElement('script');
            scriptTag.src = "https://cdn.podigee.com/podcast-player/javascripts/podigee-podcast-player.js";
            scriptTag.className = "podigee-podcast-player";
            scriptTag.setAttribute("data-configuration", episode.embed_config || "");

            scriptContainer.current.appendChild(scriptTag);
        }
    }, [episode.embed_config]);


    return (
        <div className="SingleEpisode" style={{display: visible ? 'block' : 'none'}}>
            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-3">
                    <img src={episode.image.fixed.src || null} alt={episode.guest || null} srcSet={episode.image.fixed.srcSet || null} className="SingleEpisode__image"/>
                </div>
                <div className="col-xs-12 col-sm-8 col-md-9">
                    <h3 className="SingleEpisode__title">
                        <span className="SingleEpisode__episode">{`Episode ${episode.episode}: `}</span>
                        {episode.guest}
                    </h3>
                    <p className="SingleEpisode__description">{episode.description ? episode.description.description : null}</p>
                    <div className="SingleEpisode__player" ref={scriptContainer} />
                </div>
            </div>
        </div>
    )
};

SingleEpisode.propTypes = {
    episode: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired
};

export default SingleEpisode;