import React from "react";

const Divider = props => (
    <svg width="1101px" height="10px" viewBox="0 0 1101 10" {...props}>
        <path
            d="M556.967 558c1.259 0 1.889-4.578 1.889-13.733l.333-46.691v-32.96c.222-41.198.333-112.609.333-214.231 0-65.918.37-195.922 1.111-390.013.667-173.034 1-302.122 1-387.266 0-10.07-.222-15.106-.666-15.106-.445 0-.815.916-1.111 2.747-.445 3.662-.778 9.613-1 17.852-.075 6.409-.112 14.19-.112 23.346 0 66.833-.296 180.816-.888 341.948-.593 161.132-.89 274.199-.89 339.2 0 41.2-.222 101.624-.666 181.274-.444 80.566-.667 141.448-.667 182.647 0 5.493.056 8.697.167 9.613.111.915.5 1.373 1.167 1.373z"
            transform="translate(-169 -1682) translate(161 1679) rotate(89.5 558.633 8)"
            fill="#F4F4F4"
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1}
        />
    </svg>
);

export default Divider;
