import React from "react";
import "./StreamingServices.scss";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

export const badgeFormat = graphql`
    fragment badgeFormat on File {
        childImageSharp {
            fixed(height: 50) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`;

const query = graphql`
    query {
        spotify: file(relativePath: { eq: "badges/spotify.png" }) {
            ...badgeFormat
        }
        apple: file(relativePath: { eq: "badges/apple.png" }) {
            ...badgeFormat
        }
        deezer: file(relativePath: { eq: "badges/deezer.png" }) {
            ...badgeFormat
        }
        podbean: file(relativePath: { eq: "badges/podbean.png" }) {
            ...badgeFormat
        }
    }
`;

const StreamingServices = () => {
    const data = useStaticQuery(query);
    return (
        <div className="StreamingServices">
            <span className="StreamingServices__label">Jetzt reinhören:</span>
            <a href="https://open.spotify.com/show/5lLQXnFNjzyUjlT2BJkMUV" title="Mittelrhein Podcast auf Spotify" target="_blank" className="StreamingServices__badge-link" rel="noopener noreferrer" >
                <Img fixed={data.spotify.childImageSharp.fixed} alt="Spotify" className="StreamingServices__badge" />
            </a>
            <a href="https://podcasts.apple.com/de/podcast/mittelrhein-podcast/id1510429463" title="Mittelrhein Podcast auf Apple Podcasts" target="_blank" className="StreamingServices__badge-link" rel="noopener noreferrer" >
                <Img fixed={data.apple.childImageSharp.fixed} alt="Apple Podcasts" className="StreamingServices__badge" />
            </a>
            <a href="https://www.deezer.com/show/1154042" title="Mittelrhein Podcast auf Deezer" target="_blank" className="StreamingServices__badge-link" rel="noopener noreferrer" >
                <Img fixed={data.deezer.childImageSharp.fixed} alt="Deezer" className="StreamingServices__badge" />
            </a>
            <a href="https://www.podbean.com/podcast-detail/cd33p-c7231/Mittelrhein-Podcast" title="Mittelrhein Podcast auf Podbean" target="_blank" className="StreamingServices__badge-link" rel="noopener noreferrer" >
                <Img fixed={data.podbean.childImageSharp.fixed} alt="Podbean" className="StreamingServices__badge" />
            </a>
        </div>
    );
};

export default StreamingServices;