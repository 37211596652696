import React from "react";
import PropTypes from "prop-types";
import "./EpisodeArrow.scss";
import {Left, Right} from "../images/svg/Arrows";

const EpisodeArrow = (props) => {
    const {episode, direction, onClick} = props;

    if(!episode) {
        return <div />;
    }

    return (
        <button className={"EpisodeArrow EpisodeArrow--" + direction} onClick={onClick} title={`Episode ${episode.episode}: ${episode.guest}`}>
            { direction === "left" ? <Left className="EpisodeArrow__arrow" /> : <Right className="EpisodeArrow__arrow" /> }
            <div className="EpisodeArrow__text">
                <div className="EpisodeArrow__episode">Episode {episode.episode}</div>
                <div className="EpisodeArrow__guest">{episode.guest}</div>
            </div>
        </button>
    )
};

EpisodeArrow.propTypes = {
    episode: PropTypes.object,
    direction: PropTypes.oneOf(["left", "right"]).isRequired,
    onClick: PropTypes.func.isRequired
};

export default EpisodeArrow;