import React, {useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import Divider from "../images/svg/Divider";
import SingleEpisode from "./SingleEpisode";
import './Episodes.scss';
import EpisodeArrow from "./EpisodeArrow";

const episodesQuery = graphql`
    query allEpisodes {
        allContentfulPodcastEpisode(sort: {fields: episode, order: DESC}) {
            edges {
                node {
                    id
                    episode
                    guest
                    description {
                        description
                    }
                    image {
                        fixed(width: 255, height: 255, resizingBehavior: FILL, cropFocus: FACES) {
                            aspectRatio
                            src
                            srcSet
                        }
                    }
                    embed_config
                }
            }
        }
    }
`;

const Episodes = () => {

    const episodeQuery = useStaticQuery(episodesQuery);

    const episodes = episodeQuery.allContentfulPodcastEpisode.edges.map((edge) => { return edge.node; });
    const [currentIndex, updateCurrentIndex] = useState(0);

    const goLeft = () => {
        if(currentIndex+1 < episodes.length) {
            updateCurrentIndex(currentIndex+1);
        }
    };
    const goRight = () => {
        if(currentIndex > 0) {
            updateCurrentIndex(currentIndex-1);
        }
    };

    return (
        <div className="Episodes">
            <div className="container">
                <div className="Episodes__navigation Episodes__navigation--top">
                    <EpisodeArrow episode={episodes[currentIndex+1] || null} direction="left" onClick={goLeft} />
                    <EpisodeArrow episode={episodes[currentIndex-1] || null} direction="right" onClick={goRight} />
                </div>
                <Divider className="Episodes__divider Episodes__divider--top"/>
                <div className="Episodes__carousel">
                    {
                        episodes.map((episode, index) => <SingleEpisode key={episode.id} visible={index === currentIndex} episode={episode}/>)
                    }
                </div>
                <Divider className="Episodes__divider"/>
                <div className="Episodes__navigation Episodes__navigation--bottom">
                    <EpisodeArrow episode={episodes[currentIndex+1] || null} direction="left" onClick={goLeft} />
                    <EpisodeArrow episode={episodes[currentIndex-1] || null} direction="right" onClick={goRight} />
                </div>
            </div>
        </div>
    )
};
export default Episodes;